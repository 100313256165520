import { commaSeparated } from "@/store/utils";

/**
 * @param {string} number1
 * @param {string} number2
 * @param {string} numberIterations
 */
export function calculateFibonacci({ number1, number2, numberIterations }) {
  const sequence = [];

  let numberPrevious = BigInt(number1);
  let numberCurrent = BigInt(number2);
  let result;

  sequence.push(getSeqObj(1n, numberPrevious));
  sequence.push(getSeqObj(2n, numberCurrent));
  for (let i = 3n; i <= BigInt(numberIterations); i++) {
    result = numberPrevious + numberCurrent;
    sequence.push(getSeqObj(i, result));
    numberPrevious = numberCurrent;
    numberCurrent = result;
  }

  return { sequence, sum: commaSeparated(getSumSeq(sequence)) };
}

/**
 * @typedef {{iteration: string, number: string}} Iteration
 */

/**
 * @param {BigInt} i
 * @param {BigInt} number
 * @return {Iteration}
 */
function getSeqObj(i, number) {
  return {
    iteration: commaSeparated(i),
    number: commaSeparated(number)
  };
}

/**
 * @param {Iteration[]} sequenceRaw
 * @return {BigInt}
 */
function getSumSeq(sequenceRaw) {
  const sequence = sequenceRaw.map(({ number }) => number);
  return getSumEval(sequence);
}

/**
 * @param {string[]} sequence
 * @returns {BigInt}
 */
function getSumEval(sequence) {
  return sequence.reduce((sum, number) => sum + BigInt(number.toString().replace(/,/g, "")), 0n);
}
